<!--
 * @Date: 2024-09-12 15:55:07
 * @LastEditors: kevinzgai zhanggai_ok@126.com
 * @LastEditTime: 2024-11-11 11:37:38
 * @FilePath: \zyt-mobile-frontend\src\views\me\screenDemand\modules\selectAreaPoint.vue
-->
<template>
  <div>
    <van-popup
      v-model="show"
      position="bottom"
      show-action
      round
      :style="{ MaxHeight: '30%',minHeight: '60%' }"
    >
      <!-- <van-picker
        title="选择放映点"
        show-toolbar
        :columns="pointList"
        @cancel="show = false"
        @confirm="onConfirm"
      /> -->
      <!--      标题-->
      <!--      <div class="screening-schedule-address-title">-->
      <!--        <span>选择放映点：</span>-->
      <!--      </div>-->
      <van-search
        show-action
        v-model="queryParam.search"
        placeholder="请输入搜索关键词"
        @search="onSearch"
      >
        <template #action>
          <div @click="onSearch">搜索</div>
        </template>
      </van-search>
      <div class="custom">
        <van-cell
          v-for="item in pointList"
          :key="item.id"
          :title="item.name"
          :label="item.text"
          @click="onConfirm(item)"
        >
          <template #title>
            <span class="custom-title">{{ item.name }}</span>
          </template>
        </van-cell>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { api } from '@/api'
import { Dialog, Toast } from 'vant'

export default {
  name: 'SelectAreaPoint',
  data() {
    return {
      pointList: [],
      show: false,
      userInfo: {},
      queryParam: {
        pageNum: 1,
        pageSize: 100,
        search: '',
      },
    }
  },
  methods: {
    // 根据当前集体代表用户获取该用户所在村的放映点
    open() {
      console.log('open', this.$store.state.userInfo)
      // this.userInfo = this.$store.state.userInfo
      // this.queryParam.regionAreaId = this.userInfo.regionAreaId
      this.getPointList()
    },
    onSearch() {
      console.log('onSearch', this.queryParam)
      this.getPointList()
      // this.queryParam.pageNum = 1
      // this.getPointList()
    },
    getPointList() {
      this.show = true
      Toast.loading({
        duration: 0,
        forbidClick: true,
      })
      api.filmmaker.getShowPointsMine(this.queryParam).then((res) => {
        const { success, result } = res
        console.log(res)
        Toast.clear()
        if (success) {
          const { records } = result
          // console.log(records)

          this.pointList = [{
            name: '全部',
            text: '全部放映点',
            id: '',
          }]
          records.map((item) => {
            this.pointList.push({
              ...item,
              text: `${item.address}`,
            })
          })
        }
      })
    },
    onConfirm(item) {
      console.log({item})
      if (item.id === 0) {
        this.$emit('onFinish', item)
        this.show = false
        return
      }
      Dialog.confirm({
        title: '确认选择',
        message: `确认选择${item.name}作为放映点吗？`,
        confirmButtonText: '确定',
        confirmButtonColor: '#1492ff',
        cancelButtonText: '取消',
      }).then(() => {
        // this.onFinish(item)
        this.$emit('onFinish', item)
        this.show = false
      })
    },
  },
}
</script>

<style lang="less" scoped>
.custom {
  max-height: 50vh;
  overflow-y: scroll;
}

.custom-title {
  font-weight: bold;
}

.screening-schedule-address-title {
  font-size: 14px;
  display: flex;
  padding: 0 12px;
  justify-content: space-between;
  align-items: center;
}
</style>
